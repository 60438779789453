import styles from './Partners.module.css'
import thx from '../../images/thx.jpg'
import p1 from '../../images/SC.jpg'
import p2 from '../../images/dalpstr.png'
import p3 from '../../images/LSR.jpg'
import p4 from '../../images/velikoross-1.png'
function partnerts(){
    return(
        <div className={styles.partners}>
            <div className={styles.thanks}>
                <h2 className={styles.h2}>Благодарности</h2>
                <div>
                    <img src={thx} alt="" width="225px" height="300px" className={styles.thx}/>
                </div>
            </div>
            <div className={styles.partnersMain}>
                <h2 className={styles.h2}>Партнеры</h2>
                <div className={styles.partners_div}>
                <div>
                    <div className={styles.partners_element}>
                        <img src={p1} alt="" width="200px" height="100px" className={styles.img}/>
                        <div>
                            <h3 className={styles.h3}>Сетл сити</h3>
                            <p className={styles.text}>Setl City — девелоперская компания, член финансово-промышленного холдинга Setl Group.</p>
                        </div>
                    </div>
                    <div className={styles.partners_element}>
                        <img src={p2} alt="" width="200px" height="100px" className={styles.img}/>
                        <div>
                            <h3 className={styles.h3}>Дальпитерстрой</h3>
                            <p className={styles.text}>Дальпитерстрой — крупный строительный холдинг Санкт-Петербурга и Ленинградской области, который работает с 1998 года.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.partners_element}>
                        <img src={p3} alt="" width="200px" height="100px" className={styles.img}/>
                        <div>
                            <h3 className={styles.h3}>ЛСР</h3>
                            <p className={styles.text}>ЛСР — российская компания, работающая в сфере девелопмента и производства стройматериалов.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.partners_element}>
                        <img src={p4} alt="" width="200px" height="100px" className={styles.img}/>
                        <div>
                            <h3 className={styles.h3}>Великоросс</h3>
                            <p className={styles.text}>Компания Великоросс - российский производитель готовых продуктов питания с 1993 года.</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default partnerts