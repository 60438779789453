import styles from './Contacts.module.css'
function Contacts(){
    return(
        <div>
            <div className={styles.main}>
                <div className={styles.contacts}>
                    <h2 className={styles.h2}>Контакты</h2>
                    <p className={styles.text}>Офис: ......, г. Санкт-Петербург,<br />
                    ул. ....., д .., кор. ..</p>
                    <p className={styles.text}>Склад: г. Санкт-Петербург, ул.<br />........, д. ..</p>
                    <p className={styles.text}>8 (999) 999 99-99</p>
                    <p className={styles.text}>8 (888) 888-88-88</p>
                </div>          
                <div className={styles.requisites}>
                    <h2 className={styles.h2}>Реквизиты</h2>
                    <p className={styles.text}>ООО «Вектор электро»</p>
                    <p className={styles.text}>
                    ИНН 111111111 <br />
                    КПП 111111111 <br />
                    ОГРН 1111111111111 <br />
                    </p>
                </div>
            </div>
            <div className={styles.mapdiv}>
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A2e944d61de40a46a2ca83c545d32ac03028ebe4b1cbb69b1a1f99857005eacf0&amp;source=constructor" width="1203" height="599" frameborder="0" className={styles.map}></iframe>
            </div>
        </div>
    )
}
export default Contacts