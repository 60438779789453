import styles from './Services.module.css'
import { Link } from 'react-router-dom'
function Services(){
    return(
        <div className={styles.catalog}>
            <div className={styles.catalog_info}>
                <p className={styles.h1}>Электрооборудование</p>
                <div className={styles.divp}>
                    <p className={styles.p}>Наша компания также предоставляет светотехнику, прокладку кабельных линий, устройства молниизащиты, щитовые оборудования</p> <br/>
                    <p className={styles.p}>Подробности можно узнать позвонив нам</p>
                </div>
            </div>
            <div>
            <h2 className={styles.h2}>Мы можем предоставить</h2>
            <div className={styles.about}>
                <div className={styles.aboutdiv}>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Светотехнику</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно</p>
                    </div>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Прокладку каб. линий</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно
                        </p>
                    </div>
                </div>
                <div classNames={styles.biodiv}>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Устройства молниизащиты</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно</p>
                    </div>
                </div>
                <div className={styles.biodiv}>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Щитовые оборудования</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default Services