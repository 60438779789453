import styles from './Catalog.module.css'
import { Link } from 'react-router-dom'
function Catalog(){
    return(
        <div className={styles.catalog}>
            <div className={styles.catalog_info}>
                <p className={styles.h1}>Строительные работы</p>
                <div className={styles.divp}>
                    <p className={styles.text}>Наша компания предоставляет различные строительные работы по доступным ценам и с отличным качеством</p> <br/>
                    <p className={styles.text}>Подробности можно узнать позвонив нам</p>
                </div>
            </div>
            <div>
            <h2 className={styles.h2}>Мы предоставляем услуги</h2>
            <div className={styles.about}>
                <div className={styles.aboutdiv}>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Малярных работ</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно</p>
                    </div>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Гипрочных работ</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно
                        </p>
                    </div>
                </div>
                <div classNames={styles.biodiv}>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Столярных работ</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно</p>
                    </div>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Плиточных работ</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно
                        </p>
                    </div>
                </div>
                <div className={styles.biodiv}>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Бетонных работ</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно</p>
                    </div>
                    <div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Монтаж инженерных с-м</h3>
                        </div>
                        <p className={styles.text}>Тут можно написать что-то если нужно
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default Catalog
