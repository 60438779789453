import styles from './Object.module.css'
import o1 from '../../images/object1.jpg'
import o2 from '../../images/object2.jpg'
import o3 from '../../images/object3.jpg'
function Object(){
    return(
        <div className={styles.object_main}>
            <div className={styles.h1_div}>
                <h1 className={styles.h1}>Объекты</h1>
            </div>
            <div className={styles.objects}>
                <div className={styles.objects_column}>
                    <div className={styles.object}>
                        <img src={o1} alt="" width="300px" height="300px" className={styles.img}/>
                        <div className={styles.text_div}>
                            <h3 className={styles.h3}>Name</h3>
                            <p className={styles.text}>Text</p>
                        </div>
                    </div>
                </div>
                <div className={styles.objects_column}>
                    <div className={styles.object}>
                        <img src={o2} alt="" width="300px" height="300px" className={styles.img}/>
                        <div className={styles.text_div}>
                            <h3 className={styles.h3}>Name</h3>
                            <p className={styles.text}>Text</p>
                        </div>
                    </div>
                </div>
                <div className={styles.objects_column}>
                    <div className={styles.object}>
                        <img src={o3} alt="" width="300px" height="300px" className={styles.img}/>
                        <div className={styles.text_div}>
                            <h3 className={styles.h3}>Name</h3>
                            <p className={styles.text}>Text</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Object