import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import SC from '../../images/SC.jpg'
import LSR from '../../images/LSR.jpg'
import VLKRS from '../../images/velikoross-1.png'
import sert from '../../images/i.jpeg'
import lic from '../../images/l.jpg'
import DLPBSTR from '../../images/dalpstr.png'
import img from '../../images/2b7a0791a29f11efa13bb66da8edef57_1.jpeg'
import styles from './Midle.module.css'
function Midle(){
    return(
        <div className={styles.midle}>
            <div className={styles.h1div}>
                <h1 className={styles.h1}>Надежность репутации - наша ценность</h1>
                <img src={img} alt="" width="1300px" height="500px"/>
            </div>
            <div className={styles.h2div}>
                <h2 className={styles.h2}>Почему выбирают нас</h2>
            </div>
            <div className={styles.bio}>
                <div className={styles.biodiv}>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Ваше удобство в приоритете</h3>
                        </div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Мы строим, вы отдыхаете</h3>
                        </div>
                </div>
                <div classNames={styles.biodiv}>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Дизайн и функциональность</h3>
                        </div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Бетонная репутация</h3>
                        </div>
                </div>
                <div className={styles.biodiv}>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Мы - специалисты по ремонту жилья</h3>
                        </div>
                        <div className={styles.h3div}>
                            <h3 className={styles.h3}>Железобетонное качество</h3>
                        </div>
                </div>
            </div>
        <div className={styles.sponsor}>
            <div className={styles.h2div}>
            <h2 className={styles.h2}>С нами работают</h2>
            </div>
            <div className={styles.sponsordiv}>
                <div className={styles.sponsorname}>
                    <img src={DLPBSTR} alt="" width="150px" height="70px" className={styles.img}/>
                    <p className={styles.text}> <h4 className={styles.h4}>Дальпитерстрой</h4>строительная компания</p>
                </div>    
                <div className={styles.sponsorname}>
                    <img src={LSR} alt="" width="150px" height="70px" className={styles.img}/>
                    <p className={styles.text}> <h4 className={styles.h4}>ЛСР</h4>строительная компания</p>
                </div> 
                <div className={styles.sponsorname}>
                    <img src={SC} alt="" width="150px" height="70px" className={styles.img}/>
                    <p className={styles.text}> <h4 className={styles.h4}>Сетл сити</h4>строительная компания</p>
                </div> 
                <div className={styles.sponsorname}>
                    <img src={VLKRS} alt="" width="150px" height="70px" className={styles.img}/>
                    <p className={styles.text}> <h4 className={styles.h4}>Великоросс</h4>фабрика вкусной еды</p>
                </div>
            </div>
        </div>
        <div>
        <div className={styles.h2div}>
            <h2 className={styles.h2}>О нас</h2>
        </div>
            <div className={styles.about}>
                <h3 className={styles.h3}>Строительная компание VectorElectro</h3>
                <p className={styles.text}>Текст про компанию, чем занимается какие услуги предоставляет и тд</p>
            </div>
        </div>
            <div className={styles.sert}>
            <div className={styles.h2div}>
                <h2 className={styles.h2}>Сертификаты</h2>
                </div>
                <div className={styles.sponsordiv}>
                    <div className={styles.sertename}>
                        <img src={sert} alt="" width="200px" height="130px" className={styles.serte}/>
                        <p className={styles.text}> <h4 className={styles.h4}>Сертификат</h4>пустой сертификат</p>
                    </div>    
                    <div className={styles.sertename}>
                        <img src={sert} alt="" width="200px" height="130px" className={styles.serte}/>
                        <p className={styles.text}> <h4 className={styles.h4}>Сертификат</h4>пустой сертификат</p>
                    </div> 
                    <div className={styles.sertename}>
                        <img src={sert} alt="" width="200px" height="130px" className={styles.serte}/>
                        <p className={styles.text}> <h4 className={styles.h4}>Сертификат</h4>пустой сертификат</p>
                    </div> 
                </div>
            </div>  
            <div className={styles.lic}>
            <div className={styles.h2div}>
                <h2 className={styles.h2}>Лицензии</h2>
            </div>
                <div className={styles.sponsordiv}>
                    <div className={styles.sponsorname}>
                        <img src={lic} alt="" width="120px" height="150px" className={styles.lice}/>
                        <p className={styles.text}> <h4 className={styles.h4}>Лицензия</h4>пустая лицензия</p>
                    </div>    
                    <div className={styles.sponsorname}>
                        <img src={lic} alt="" width="120px" height="150px" className={styles.lice}/>
                        <p className={styles.text}> <h4 className={styles.h4}>Лицензия</h4>пустая лицензия</p>
                    </div> 
                    <div className={styles.sponsorname}>
                        <img src={lic} alt="" width="120px" height="150px" className={styles.lice}/>
                        <p className={styles.text}> <h4 className={styles.h4}>Лицензия</h4>пустая лицензия</p>
                    </div> 
                </div>
            </div> 
        </div>
    )
}
export default Midle