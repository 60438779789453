import { NavLink } from 'react-router-dom'
import styles from './Basement.module.css'
function Basement(){
    return(
    <div className={styles.basement}>
            <div className={styles.catalog}>
                <b><p className={styles.p}>VectorElectro</p></b>
                                <p className={styles.link}>Адрес Адрес Адрес Адрес Адрес Адрес</p>
                                <p className={styles.link}>+7 777 777 77 77</p>
                                <p className={styles.link}>veсtorelectro@mail.ru</p>
            </div>
            <div className={styles.catalog}>
                <b><NavLink to="/services" className={styles.link}>Электрооборудование</NavLink></b>
                                <NavLink to="/catalog/equipment" className={styles.link}>Светотехника</NavLink>
                                <NavLink to="/catalog/equipment" className={styles.link}>Прокладка кабельных линий</NavLink>
                                <NavLink to="/catalog/equipment" className={styles.link}>Устройство системы молниезащиты</NavLink>
                                <NavLink to="/catalog/equipment" className={styles.link}>Щитовые оборудования</NavLink>
            </div>
            <div className={styles.catalog}>
                <b><NavLink to="/catalog" className={styles.link}>Строительные работы</NavLink></b>
                                <NavLink to="/catalog/equipment" className={styles.link}>Малярные работы</NavLink>
                                <NavLink to="/catalog/equipment" className={styles.link}>Гипрочные работы</NavLink>
                                <NavLink to="/catalog/equipment" className={styles.link}>Столярные работы</NavLink>
                                <NavLink to="/catalog/equipment" className={styles.link}>Плиточные работы</NavLink>
                                <NavLink to="/catalog/equipment" className={styles.link}>Бетонные работы</NavLink>
                                <NavLink to="/catalog/equipment" className={styles.link}>Монтаж инженерные систем</NavLink>
            </div>
            <div className={styles.catalog}>
                <NavLink to="/object" className={styles.link}>Объекты</NavLink>
                <NavLink to="/partners" className={styles.link}>Партнеры</NavLink>
                <NavLink to="/about" className={styles.link}>О компании</NavLink>
                <NavLink to="/contacts" className={styles.link}>Контакты</NavLink>
            </div>
    </div>
    )
}
export default Basement